import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Watch from '../../Assets/images/platinum/Watch-Repair.png';
import Jewelery from '../../Assets/images/platinum/Jewelry-Repair.png';

const JewelryRepair = () => {
  return (
    <>
      <section className="jewelry_repair_section">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={5} md={6}>
              <div className="j_reapair_catelogbox">
                <img src={Watch} alt="catelog img" />
                <div className="j_repair_text">
                  <h3>Watch Repair Services</h3>
                  <Link className="j_repair_btn" to="/watch-service-repair">
                    View
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={5} md={6}>
              <div className="j_reapair_catelogbox">
                <img src={Jewelery} alt="catelog img" />
                <div className="j_repair_text">
                  <h3> Jewelry Repair Services</h3>
                  <Link className="j_repair_btn" to="/jewelry-repaire-services">
                    View
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default JewelryRepair;
