import React from 'react'
import Banner from "../../Assets/images/best-seller-banner.jpg"
import MobBanner from "../../Assets/images/best-seller-mob-banner.jpg"
import { Container, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const ShyCreation = () => {
  return (
    <div className='shy-creation-wrapper'>
        <Image src={Banner} className='d-md-block d-none' alt="Shy Creation Banner" fluid />
        <Image src={MobBanner} className='d-md-none d-block' alt="Shy Creation Banner" fluid />
        
        <Container>
            <div className='shy-creation-content'>
                <h3>Best Sellers Jewelry</h3>
                <p>Discover timeless elegance with Best Sellers Jewelry's curated collection of exquisite pieces.</p>
                <Link className="sj_shop_fill_btn mt-3" to="/jewelry-brand-list/Shy%20Creations">Shop Now</Link>
            </div>
        </Container>
    </div>
  )
}

export default ShyCreation;