import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../Assets/css/home.css";

const Insta = () => {
  return (
    <>
      <Container>
        <Row>
          <div className="rcs_news_content1 w-100 mt-5">
            <h2 className="text-center">View our Instagram</h2>
            <p class="text-center">
              {" "}
              Follow us on Instagram{" "}
              <a
                href="https://www.instagram.com/alicias_jewelers/"
                target="blank" className="text-decoration-underline font-weight-bold"
              >
                {" "}
                @alicias_jewelers
              </a>
            </p>
          </div>
        </Row>
      </Container>
      {/* <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> */}
      <Container fluid>
        <Row>
          <Col sm={12} className="p-0">
            <iframe title="Instagram feeds and posts"
              src="https://instagram.demobw.live/alicias-jewelers/"
              frameborder="0"
              width="100%"
              className="insta-iframe"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Insta;
