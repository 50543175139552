import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LooseDiamond from '../../Assets/images/platinum/loose.png';
import RingSet from '../../Assets/images/platinum/create.png';

const LooseCreateDiamond = () => {

    return (
        <>

            <section className="loose_creatD_section">
                <Container fluid>

                    <Row className='justify-content-center'>
                        <Col lg={5} md={6}>
                            <div className="catelogbox">
                                <Link to="/diamonds"><img src={LooseDiamond} alt="catelog img" /><span className='d-none'>Diamond Ring</span></Link>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className="catelogbox">
                                <Link to="/ringsettings"><img  src={RingSet} alt="catelog img" /><span className='d-none'>Diamond Ring</span></Link>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

        </>
    );
}
export default LooseCreateDiamond;