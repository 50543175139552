import React, { useEffect, useRef, useState } from "react";
import "../../Assets/css/home.css";
import { Col, Row, Container, Carousel } from "react-bootstrap";

import { NavLink, useHistory } from "react-router-dom";
// import Slider from "react-slick";

// import Banner1 from "../../Assets/images/alicias-img/engagment-banen-desktop.webp";
// import Banner2 from "../../Assets/images/alicias-img/fine-banner-desktop.webp";
import LazyLoad from "react-lazy-load";
import axios from "axios";
import { base_url, postHeader } from "../../Helpers/request";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { isMobile } from "react-device-detect";

const BannerSlider = () => {
  const carouselRef = useRef(null);

  const handleSlide = () => {
    setTimeout(() => {
      document.querySelectorAll(".carousel-item").forEach((slide) => {
        const isHidden = slide.classList.contains("active") ? "0" : "-1";
        slide.querySelectorAll("a, button").forEach((el) => {
          el.setAttribute("tabIndex", isHidden);
        });
      });
    }, 100);
  };

  useEffect(() => {
    handleSlide(); // Run on load
  }, []);

  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true);
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setLoading(false);
          setBanner(response.data.data);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerPadding: "20px",
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <LazyLoad className="lazyLoad-heroBanner" width="100%" threshold={0.6}>
        <div className="bannerup">
          <div className="banner-slider hide-mob">
            <Container fluid className="rcs_custom_banner_container p-0">
              <Row className="w-100 m-0">
                <Col lg={12} className="p-0">
                  <div className="bannner_banenr_slider ali_banner_slider">
                    <Carousel
                      fade
                      indicators={false}
                      prevLabel="Go to previous slide"
                      nextLabel="Go to next slide"
                      ref={carouselRef}
                      onSlide={handleSlide}
                      prevIcon={
                        <span className="carousel-control-prev-icon" role="img" aria-label="Go to previous slide"></span>
                      }
                      nextIcon={
                        <span className="carousel-control-next-icon" role="img" aria-label="Go to next slide"></span>
                      }
                    >
                      {loading ? (
                        <Skeleton
                          height={!isMobile ? "680px" : "225px"}
                          style={{
                            transform: "scale(1)",
                            background: "#f7f7f7",
                          }}
                          animation="wave"
                        />
                      ) : (
                        banner?.map((itme, index) => (
                          <Carousel.Item key={index}>
                            <div className="pd_banner_slider_main">
                              <NavLink to={itme.btn_link}>
                                <img src={itme.image} alt="Christmas Banner" />
                              </NavLink>
                            </div>
                          </Carousel.Item>
                        ))
                      )}
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default BannerSlider;
