import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import TitleShape from "../../Assets/images/platinum/line-black.png";
import Eng from "../../Assets/images/platinum/Ex/engagement_ring.jpg";
import Neck from "../../Assets/images/platinum/Ex/necklace.jpg";
import WedRing from "../../Assets/images/platinum/Ex/wedding_ring.jpg";
import Ear from "../../Assets/images/platinum/Ex/earrings.jpg";

const ExploreCollection = () => {
  var explore = {
    dots: false,
    infinite: false,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    beforeChange: () => {
      setTimeout(() => {
        document.querySelectorAll(".slick-slide[aria-hidden='true']").forEach((slide) => {
          slide.querySelectorAll("a, button").forEach((el) => {
            el.setAttribute("tabIndex", "-1"); // Disable focus
          });
        });
  
        document.querySelectorAll(".slick-slide[aria-hidden='false']").forEach((slide) => {
          slide.querySelectorAll("a, button").forEach((el) => {
            el.setAttribute("tabIndex", "0"); // Enable focus
          });
        });
      }, 100); // Delay to allow Slick to update
    },
  };

  return (
    <>
      <section className="sj_explore_collection_section">
        <Container fluid>
          <Row>
            <Col lg="12" col="12">
              <div className="sj_title_main">
                <h2> EXPLORE OUR COLLECTION </h2>
                <img src={TitleShape} alt="exp" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="p-0">
              <Slider
                {...explore}
                className="sj_explore_collection_slider"   
                data-aos="fade-up"
              >
                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src={WedRing} alt="wedtings" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> Engagement Rings </h3>
                      </div>
                      <p>
                        Select your perfect Engagement Ring with diamond. Choose
                        from wide range of designer rings.
                      </p>
                      {/* <button className="sj_shop_fill_btn"> */}
                        <NavLink className="sj_shop_fill_btn" to="/ringsettings" aria-label="Shop Now"> Shop Now </NavLink>
                      {/* </button> */}
                    </div>
                  </div>
                </div>

                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <img src={Neck} alt="necklaces" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> Necklaces </h3>
                      </div>
                      <p>
                        Enhance your neck with our jaw dropping necklaces that
                        flatter a simple as well as bold look and looks good on
                        any attire.
                      </p>
                      {/* <button className="sj_shop_fill_btn"> */}
                        <NavLink className="sj_shop_fill_btn" to="/jewelry/necklaces" aria-label="Shop Now">Shop Now</NavLink>
                      {/* </button> */}
                    </div>
                  </div>
                </div>

                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  <img src={Eng} alt="Wedding rings" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> Wedding Rings </h3>
                      </div>
                      <p>
                        Find the perfect symbol of your love. Discover eternity
                        diamond rings, classic bands, and unique styles.
                      </p>
                      {/* <button className="sj_shop_fill_btn"> */}
                        <NavLink className="sj_shop_fill_btn" to="/jewelry/wedding-rings" aria-label="Shop Now">Shop Now</NavLink>
                      {/* </button> */}
                    </div>
                  </div>
                </div>

                <div
                  className="sj_explore_item_main"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src={Ear} alt="aerrings" />
                  <div className="sj_explore_hover_content">
                    <div>
                      <div className="sj_explore_title">
                        <h3> earrings </h3>
                      </div>
                      <p>
                        Explore our exciting varieties of stylish earrings from
                        short and sweet to long and dramatic.
                      </p>
                      {/* <button className="sj_shop_fill_btn"> */}
                        <NavLink className="sj_shop_fill_btn" to="/jewelry/earrings" aria-label="Shop Now">Shop Now</NavLink>
                      {/* </button> */}
                    </div>
                  </div>
                </div>

                {/* <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="1500">
                                    <img src={Eng} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> Engagement Rings </h3>
                                            </div>
                                            <p> Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings. </p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/ringsettings"> Shop Now </NavLink> </button>
                                        </div>
                                    </div>
                                </div> */}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ExploreCollection;
